import { RootState } from 'redux/store'

export const contactInfoFormSelector = (state: RootState) =>
  state.common.forms.contactInfo

export const locationAndFleetFormSelector = (state: RootState) =>
  state.common.forms.locationAndFleet

export const currentStepSelector = (state: RootState) =>
  state.common.currentStep

export const stationTypesSelector = (state: RootState) =>
  state.common.stationTypes
