import { createSlice } from '@reduxjs/toolkit'
import { CommonState } from './types'

export const initialCommonState: CommonState = {
  forms: {
    contactInfo: null,
    locationAndFleet: null,
  },
  stationTypes: [],
  currentStep: 1,
}

export const commonSlice = createSlice({
  name: 'common',
  initialState: initialCommonState,
  reducers: {
    setCurrentStep(state, action) {
      state.currentStep = action.payload
    },
    setContactFormValues(state, action) {
      state.forms.contactInfo = action.payload
    },
    setLocationAndFleetFormValues(state, action) {
      state.forms.locationAndFleet = action.payload
    },
    resetFormValues(state) {
      state.forms = initialCommonState.forms
    },
    setStationTypes(state, action) {
      state.stationTypes = action.payload
    },
  },
})

export const commonReducer = commonSlice.reducer
export const {
  setCurrentStep,
  setContactFormValues,
  setLocationAndFleetFormValues,
  resetFormValues,
  setStationTypes,
} = commonSlice.actions
